define('signup/components/wizard-submit', ['exports', 'ember', 'signup/components/wizard-base'], function (exports, _ember, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    validateSteps: ['plan', 'business', 'entity', 'bank', 'agreement', 'security'],

    validate: function validate() {
      var valid = true;
      var data = this.get('allData');
      this.get('validateSteps').forEach(function (key) {
        if (!data[key] || !data[key].isValid) {
          console.log(key + ' is not valid...');
          valid = false;
        } else {
          console.log(key + ' is valid!');
        }
      });
      return valid;
    },

    isValid: _ember['default'].computed('allData.{plan,business,entity,bank,agreement,security}.isValid', function () {
      return this.validate();
    }),

    actions: {
      submit: function submit() {
        this.sendAction('submit');
      }
    }
  });
});