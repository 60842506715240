define('signup/components/masked-input-date', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Setup masks
      var $el = this.$('input');
      $el.mask("09/09/0000");
    }
  });
});