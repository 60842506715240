define('signup/components/wizard-business', ['exports', 'signup/components/wizard-base'], function (exports, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    mailingAsAbove: true,

    industries: ['Accommodation', 'Accountants', 'Amusements', 'Automotive', 'Books', 'Building', 'Business', 'Butcher', 'Charity', 'Childcare', 'Cleaning', 'Clothing', 'Comm Telco', 'Computer IT', 'Craft', 'Dieticians', 'Direct Marketing', 'Discount Store', 'Electronics', 'Fast Food / Cafe', 'Flooring', 'Florist', 'Furniture', 'Hair Salon', 'Hardware', 'Health', 'Hotel / Pub', 'Jewellery', 'Locksmith', 'Membership', 'Music', 'Pawnbrokers', 'Pet Store', 'Plumber', 'Real Estate', 'Restaurant', 'Sport', 'Vet', 'Wineries'],
    businessStructures: ['Sole Trader', 'Company', 'Partnership', 'Trust', 'Non Profit', 'Incorporated Body', 'Other'],
    states: ['ACT', 'NSW', 'NT', 'QLD', 'TAS', 'VIC', 'WA'],
    locationRestrictions: {
      country: "au"
    },

    validation: {
      'industry': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must choose an industry.'
      }],
      'abn': [{
        validate: function validate(value) {
          return this.validateAbn(value);
        },
        error: 'The ABN (11 digits) or ACN (9 digits) provided is not valid.'
      }],
      'type': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must choose a business type.'
      }],
      'name': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a business name.'
      }],
      'startDate': [{
        validate: function validate(value) {
          return (value || '').length === 10;
        },
        error: 'You must provide a business start date.'
      }],
      'email': [{
        validate: function validate(value) {
          return (/.+@.+\..+/.test(value)
          );
        },
        error: 'You must provide a valid email address.'
      }],
      'phone': [{
        validate: function validate(value) {
          return (value || '').replace(/ |_/g, '').length === 10;
        },
        error: 'You must provide a valid australian phone number.'
      }],
      'address': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide an address.'
      }],
      'mailingAddress': [{
        validate: function validate(value) {
          return this.get('mailingAsAbove') ? true : (value || '') !== '';
        },
        error: 'You must provide a mailing address.'
      }]
    },

    validateAbn: function validateAbn(value) {
      var abnWeights = new Array(10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19);
      var acnWeights = new Array(8, 7, 6, 5, 4, 3, 2, 1);
      var total = 0;
      var abn = (value || '').replace(/ |_/g, '').split('');
      if (abn.length === 0) {
        return true; // Allow empty
      }
      if (abn.length === 11) {
        // Validate ABN
        abn[0] = Number(abn[0]) - 1;
        for (var i = 0; i < 11; i++) {
          total += abnWeights[i] * abn[i];
        }
        return total % 89 === 0;
      }
      if (abn.length === 9) {
        // Validate ACN
        for (var i = 0; i < 8; i++) {
          total += acnWeights[i] * abn[i];
        }
        var result = 10 - total % 10;
        if (result === 10) {
          result = 0;
        }
        return result === Number(abn[8]);
      }
      return false; // Invalid
    },

    actions: {
      industryChange: function industryChange(industry) {
        this.set('data.industry', industry);
      },
      businessTypeChange: function businessTypeChange(type) {
        this.set('data.type', type);
      },
      mailingAsAbove: function mailingAsAbove(enable) {
        this.set('data.mailingAsAbove', enable);
        this.set('mailingAsAbove', enable);
      }
    }
  });
});
// import Ember from 'ember';