define('signup/components/wizard-entity', ['exports', 'ember', 'signup/components/wizard-base'], function (exports, _ember, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    signup: _ember['default'].inject.service(),

    defaults: {
      declaredBankruptcy: false,
      idType: 'Passport'
    },

    locationRestrictions: {
      country: "au"
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      // Make sure we show errors when displayed
      if (this.get('data.isValid') === false) {
        this.validate();
      }
      // Allow forcing of validation (this is very hackey =( )
      this.set('data._forceValidate', function () {
        _this.validate();
      });
    },

    // Override base!
    isVisible: _ember['default'].computed('active', function () {
      return this.get('active');
    }),

    // Override base!
    checkValidity: _ember['default'].observer('active', function () {
      if (this.get('active')) {
        this.set('wasActive', true);
        return;
      }
      if (!this.get('wasActive')) {
        return;
      }
      // If no longer active, validate
      this.set('wasActive', false);
      this.validate();
    }),

    autoSavePhotoId: _ember['default'].observer('data.photoid', function () {
      if (this.get('data.photoid')) {
        this.sendAction('saveField', 'photoid', this.get('data.photoid'));
      }
    }),
    autoSavePhotoId2: _ember['default'].observer('data.photoid2', function () {
      if (this.get('data.photoid2')) {
        this.sendAction('saveField', 'photoid2', this.get('data.photoid2'));
      }
    }),
    autoSaveBankcruptcyDischarge: _ember['default'].observer('data.bankcruptcyDischarge', function () {
      if (this.get('data.bankcruptcyDischarge')) {
        this.sendAction('saveField', 'bankcruptcyDischarge', this.get('data.bankcruptcyDischarge'));
      }
    }),

    roles: ['Sole Trader', 'Officer or Agent', 'Director', 'Company Secretary', 'Sole Director', 'Other'],
    idTypes: ['Passport', 'Drivers License'],

    validation: {
      'name': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a name.'
      }],
      'dateOfBirth': [{
        validate: function validate(value) {
          return (value || '').length === 10;
        },
        error: 'You must provide a date of birth.'
      }],
      'address': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide an address.'
      }],
      'phone': [{
        validate: function validate(value) {
          return (value || '').replace(/ |_/g, '').length === 10;
        },
        error: 'You must provide a valid australian phone number.'
      }],
      'role': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a business role.'
      }],
      'photoid': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide photo identification.'
      }],
      'photoid2': [{
        prevalidate: function prevalidate() {
          return this.get('data.idType') === 'Drivers License';
        },
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a photo of the back of your drivers license.'
      }],
      'bankcruptcyDischarge': [{
        prevalidate: function prevalidate() {
          return this.get('data.declaredBankruptcy');
        },
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a copy of your bankruptcy discharge.'
      }]
    },

    actions: {
      roleChanged: function roleChanged(role) {
        this.set('data.role', role);
      },
      photoIdError: function photoIdError(error) {
        this.set('errors.photoid', error);
      },
      photoId2Error: function photoId2Error(error) {
        this.set('errors.photoid2', error);
      },
      declaredBankruptcy: function declaredBankruptcy(declared) {
        this.set('data.declaredBankruptcy', declared);
      },
      bankcruptcyError: function bankcruptcyError(error) {
        this.set('errors.bankcruptcy', error);
      },
      idTypeChanged: function idTypeChanged(idType) {
        this.set('data.idType', idType);
      }
    }
  });
});