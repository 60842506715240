define('signup/helpers/atanh', ['exports', 'ember-math-helpers/helpers/atanh'], function (exports, _emberMathHelpersHelpersAtanh) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersAtanh['default'];
    }
  });
  Object.defineProperty(exports, 'atanh', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersAtanh.atanh;
    }
  });
});