define('signup/components/masked-input-abn', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Setup masks
      var $el = this.$('input');
      var basemask = '000 000 000 0';
      var altmask = '00 000 000 000';
      var options = {
        onKeyPress: function onKeyPress(value, e, field, options) {
          value = (value || '').replace(/ |_/g, '');
          var mask = value.length > 9 ? altmask : basemask;
          $el.mask(mask, options);
        }
      };
      $el.mask(basemask, options);
    }
  });
});