define('signup/routes/setup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    signup: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      if (this.get('session.uid')) {
        return;
      }
      return this.get('session').fetch().then(function () {
        return _this.get('signup').fetch().then(function () {
          _this.get('signup').persistLogon();
        });
      })['catch'](function (error) {
        console.log(error);
        _this.transitionTo('login');
      });
    }
  });
});