define('signup/helpers/cbrt', ['exports', 'ember-math-helpers/helpers/cbrt'], function (exports, _emberMathHelpersHelpersCbrt) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersCbrt['default'];
    }
  });
  Object.defineProperty(exports, 'cbrt', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersCbrt.cbrt;
    }
  });
});