define('signup/components/wizard-agreement', ['exports', 'ember', 'signup/components/wizard-base'], function (exports, _ember, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    agreementModal: false,

    defaults: {
      'agree': false
    },

    validation: {
      'termsAndConditionsAgreement': [{
        validate: function validate(value) {
          return value === true;
        },
        error: 'You must read and agree to the terms.'
      }]
    },

    agree: _ember['default'].observer('data.termsAndConditionsAgreement', function () {
      if (!this.get('data.termsAndConditionsAgreement')) {
        return;
      }
      if (!this.get('viewedtcs')) {
        this.set('agreementModal', true);
      }
    }),

    actions: {
      'continue': function _continue() {
        this.set('viewedtcs', true);
        this.set('data.tcsVersion', '(V024) BEN50TC032 (01/07/2019)');

        this.set('data.pricingVersion', '20180517');

        this.set('agreementModal', false);
      }
    }
  });
});