define('signup/app', ['exports', 'ember', 'signup/resolver', 'ember-load-initializers', 'signup/config/environment'], function (exports, _ember, _signupResolver, _emberLoadInitializers, _signupConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  _ember['default'].TextField.reopen({
    attributeBindings: ['data-provide', 'data-date-format', 'data-date-start-view', 'data-date-autoclose', 'data-mask']
  });

  App = _ember['default'].Application.extend({
    modulePrefix: _signupConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _signupConfigEnvironment['default'].podModulePrefix,
    Resolver: _signupResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _signupConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});