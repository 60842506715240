define('signup/components/firebase-upload', ['exports', 'ember', 'firebase'], function (exports, _ember, _firebase) {
  exports['default'] = _ember['default'].Component.extend({
    dragging: false,
    uploading: 0,
    uploadPath: '/uploads/',
    accept: "*",
    multiple: false,

    progress: [],
    uploadingTotal: _ember['default'].computed.sum('progress'),
    uploadingProgress: _ember['default'].computed('uploadingTotal', function () {
      return _ember['default'].String.htmlSafe(this.get('uploadingTotal') / this.get('progress.length'));
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$().on('dragenter', function (e) {
        e.stopPropagation();
        e.preventDefault();
        _this.set('dragging', true);
      });
      this.$().on('dragleave', function (e) {
        e.stopPropagation();
        e.preventDefault();
        _this.set('dragging', false);
      });
      this.$().on('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });
      this.$().on('drop', function (e) {
        _this.set('dragging', false);
        e.preventDefault();
        var files = e.originalEvent.dataTransfer.files;
        _this.uploadFiles(files);
      });
      $(document).on('dragenter', this.documentDragIgnore);
      $(document).on('dragover', { 'this': this }, this.documentDragOver);
      $(document).on('drop', this.documentDragIgnore);
      this.$('.drop-zone-file').on('change', function () /*e*/{
        var files = _this.$('.drop-zone-file')[0].files;
        _this.uploadFiles(files);
      });
    },

    documentDragIgnore: function documentDragIgnore(e) {
      e.stopPropagation();
      e.preventDefault();
    },

    documentDragOver: function documentDragOver(e) {
      e.stopPropagation();
      e.preventDefault();
      e.data['this'].set('dragging', false);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$().off('dragenter');
      this.$().off('dragleave');
      this.$().off('dragover');
      this.$().off('drop');
      $(document).off('dragenter', this.documentDragIgnore);
      $(document).off('dragover', this.documentDragOver);
      $(document).off('drop', this.documentDragIgnore);
    },

    uploadFiles: function uploadFiles(files) {
      this.set('progress', new Array(files.length));
      for (var i = 0; i < files.length; i++) {
        this.incrementProperty('uploading');
        this.fireBaseImageUpload(files[i], i);
      }
    },

    fireBaseImageUpload: function fireBaseImageUpload(file, fileNumber) {
      var _this2 = this;

      // expected parameters to start storage upload
      var path = this.generateRandomString(16);
      var metaData = { 'contentType': file.type };

      var fullPath = this.get('uploadPath') + '/' + path + '/' + file.name;

      var uploadFile = _firebase['default'].storage().ref().child(fullPath).put(file, metaData);

      var onProgress = this.get('onProgress') || function () {};
      var onComplete = this.get('onComplete') || function () {};
      onProgress({ progress: 0, id: path, fileType: file.type, fileName: file.name });

      uploadFile.on('state_changed', function (snapshot) {
        var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        progress = Math.floor(progress);
        _this2.get('progress')[fileNumber] = progress;
        _this2.propertyDidChange('progress');
        onProgress({ progress: progress, id: path, fileType: file.type, fileName: file.name });
      }, function (error) {
        _this2.decrementProperty('uploading');
        onComplete(error);
      }, function () {
        _this2.decrementProperty('uploading');
        var downloadURL = uploadFile.snapshot.downloadURL;
        onComplete(null, {
          downloadURL: downloadURL,
          id: path,
          fileType: file.type,
          fileName: file.name });
      });
    },

    generateRandomString: function generateRandomString(length) {
      var chars = "abcdefghijklmnopqrstuvwxyz";
      var pass = "";
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      return pass;
    }
  });
});