define('signup/services/query', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    values: {},

    init: function init() {
      this.set('values', this.getParameters());
    },

    getParameters: function getParameters() {
      var params = {};
      var tmp = undefined;
      location.search.substr(1).split("&").forEach(function (item) {
        tmp = item.split("=");
        params[tmp[0]] = decodeURIComponent(tmp[1]);
      });
      return params;
    }
  });
});