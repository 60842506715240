define('signup/initializers/register-google', ['exports', 'ember-place-autocomplete/initializers/register-google'], function (exports, _emberPlaceAutocompleteInitializersRegisterGoogle) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPlaceAutocompleteInitializersRegisterGoogle['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberPlaceAutocompleteInitializersRegisterGoogle.initialize;
    }
  });
});