define('signup/components/masked-input-phone', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Setup masks
      var $el = this.$('input');
      var mobmask = '0000 000 000';
      var landmask = '00 0000 00000';
      var options = {
        onKeyPress: function onKeyPress(value, e, field, options) {
          value = (value || '').replace(/ |_/g, '');
          var mask = value[1] === '4' ? mobmask : landmask;
          $el.mask(mask, options);
        }
      };
      $el.mask(landmask, options);
    }
  });
});