define('signup/services/signup', ['exports', 'ember', 'firebase', 'signup/utils/generate-push-id'], function (exports, _ember, _firebase, _signupUtilsGeneratePushId) {
  exports['default'] = _ember['default'].Service.extend({
    firebaseApp: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    application: {},

    persistLogon: function persistLogon() {
      var bid = this.get('businessId');
      if (!bid) {
        return;
      }
      if (this.get('application.finished')) {
        return;
      }
      this.get('firebaseApp').database().ref('/applications/' + bid + '/disconnected').onDisconnect().set(_firebase['default'].database.ServerValue.TIMESTAMP);
    },

    fetch: function fetch() {
      var _this = this;

      var isLogin = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var uid = this.get('session.uid');
      if (!uid) {
        return Promise.reject();
      }
      return this._fetchBusinessId(uid).then(function () {
        var bid = _this.get('businessId');
        if (!bid) {
          return Promise.reject();
        }
        return _this.get('firebaseApp').database().ref('/applications/' + bid + '/').once('value').then(function (snapshot) {
          var application = snapshot.val() || {};
          var FIVE_MINUTES = 5 * 60 * 1000;
          if (!isLogin && (application.disconnected || 0) + FIVE_MINUTES < new Date().getTime()) {
            return _this.get('session').close().then(function () {
              throw "Session expired!";
            });
          }
          // Fetch extended business details
          if (application.business === undefined) {
            _this._fetchBusiness(bid);
          }
          _this.set('application', application);
        });
      });
    },

    _fetchBusinessId: function _fetchBusinessId(uid) {
      var _this2 = this;

      // Gets first available business ID
      // May need to be changed in future to get the business for which this user is an owner
      return this.get('firebaseApp').database().ref('/users/' + uid + '/businesses').once('value').then(function (snapshot) {
        var bid = null;
        snapshot.forEach(function (childSnapshot) {
          if (bid) {
            return;
          }
          bid = childSnapshot.key;
        });
        _this2.set('businessId', bid);
      });
    },

    _fetchBusiness: function _fetchBusiness(bid) {
      var _this3 = this;

      this.get('firebaseApp').database().ref('/businesses/' + bid + '/').once('value').then(function (snapshot) {
        var business = snapshot.val() || {};
        var phone = business['phone'] || '';
        if (phone.length > 9) {
          phone = phone.slice(phone.length - 9);
        }
        var data = {};
        if (business['abn']) {
          data.prefilled = true;data['abn'] = business['abn'];
        }
        if (business['name']) {
          data.prefilled = true;data['name'] = business['name'];
        }
        if (business['email']) {
          data.prefilled = true;data['email'] = business['email'];
        }
        //if (phone)                { data.prefilled = true; data['phone'] = phone; }
        if (business['address']) {
          data.prefilled = true;data['address'] = business['address'];
        }
        if (data.prefilled) {
          _this3.set('application.business', data);
        }
        data = {};
        if (business['accountName']) {
          data.prefilled = true;data['settlementAccountName'] = business['accountName'];
        }
        if (business['bsb']) {
          data.prefilled = true;data['settlementAccountBsb'] = business['bsb'];
        }
        if (business['accountNumber']) {
          data.prefilled = true;data['settlementAccountNumber'] = business['accountNumber'];
        }
        if (data.prefilled) {
          _this3.set('application.bank', data);
        }
      });
    },

    save: function save() {
      var bid = this.get('businessId');
      if (!bid) {
        return Promise.reject();
      }
      if (!this.get('application.created')) {
        this.set('application.created', _firebase['default'].database.ServerValue.TIMESTAMP);
      }
      this.set('application.modified', _firebase['default'].database.ServerValue.TIMESTAMP);
      if (this.get('application.finished')) {
        // If we're finished, make sure not to set the disconnected flag
        this.get('firebaseApp').database().ref('/applications/' + bid + '/disconnected').onDisconnect().cancel();
        delete this.get('application').disconnected;
      }
      return this.get('firebaseApp').database().ref('/applications/' + bid + '/').update(JSON.parse(JSON.stringify(this.get('application'))));
    },
    saveField: function saveField(section, field, value) {
      var bid = this.get('businessId');
      value = value !== undefined ? value : this.get('application.${section}.${field}');
      if (!bid || !section || !field || value === undefined) {
        return Promise.reject();
      }
      return this.get('firebaseApp').database().ref('/applications/' + bid + '/' + section + '/' + field).set(value);
    },
    setApplicationProgress: function setApplicationProgress() {
      var businessId = this.get('businessId');
      if (!businessId) {
        return Promise.reject();
      }
      return this.get('firebaseApp').database().ref('/businesses/' + businessId + '/cloudEftposDetails').update({
        applicationProgress: 1,
        applicationState: 'Application Received'
      });
    },
    createBusinessUser: function createBusinessUser() {
      var _this4 = this;

      var uid = this.get('session.uid');
      if (!uid) {
        return Promise.reject();
      }
      var businessId = (0, _signupUtilsGeneratePushId['default'])();
      var business = {
        dataEntered: false,
        owner: uid,
        abn: "",
        address: "",
        name: "",
        phone: "",
        email: "",
        created: _firebase['default'].database.ServerValue.TIMESTAMP,
        modified: _firebase['default'].database.ServerValue.TIMESTAMP
      };
      var user = {
        email: this.get('session.currentUser.email'),
        // name
        // phone
        businesses: {}
      };
      var settings = {
        taxes: {}
      };
      settings.taxes[(0, _signupUtilsGeneratePushId['default'])()] = {
        name: "GST",
        amount: 0.1
      };
      user.businesses[businessId] = true;
      this.get('firebaseApp').database().ref('/users/' + uid).set(user);
      return this.get('firebaseApp').database().ref('/businesses/' + businessId).set(business).then(function () {
        _this4.get('firebaseApp').database().ref('/businessData/' + businessId + '/settings/').update(settings);
        _this4.set('businessId', businessId);
      });
    },

    setCloudDetails: function setCloudDetails(email, password) {
      var businessId = this.get('businessId');
      if (!businessId) {
        return Promise.reject();
      }
      return this.get('firebaseApp').database().ref('/businesses/' + businessId + '/cloudEftposDetails').update({
        name: email,
        password: password
      });
    }
  });
});