define("signup/templates/components/masked-input-date", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 65
          }
        },
        "moduleName": "signup/templates/components/masked-input-date.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "input", [], ["id", ["subexpr", "@mut", [["get", "inputId", ["loc", [null, [1, 11], [1, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "type", "tel", "data-provide", "datepicker", "data-date-format", "dd/mm/yyyy", "data-date-start-view", "decade", "data-date-autoclose", "true", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [3, 33], [3, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "dd/mm/yyyy"], ["loc", [null, [1, 0], [3, 65]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});