define('signup/helpers/sign', ['exports', 'ember-math-helpers/helpers/sign'], function (exports, _emberMathHelpersHelpersSign) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersSign['default'];
    }
  });
  Object.defineProperty(exports, 'sign', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersSign.sign;
    }
  });
});