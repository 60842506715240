define('signup/controllers/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    firebaseApp: _ember['default'].inject.service(),
    signup: _ember['default'].inject.service(),
    query: _ember['default'].inject.service(),

    authenticating: false,

    reset: function reset() {
      var resetFields = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      if (resetFields) {
        this.set('email', '');
        this.set('emailconfirm', '');
        this.set('password', '');
      }
      this.set('error', null);
      this.set('emailError', null);
      this.set('emailCopyError', null);
      this.set('passwordError', null);
    },

    validateCredentials: function validateCredentials() {
      var validatePasswordComplexity = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var email = this.get('email');
      var emailcopy = this.get('emailconfirm');
      var password = this.get('password') || "";
      this.reset(false);
      if (!/.+@.+\..+/.test(email)) {
        this.set('emailError', 'You must enter a valid email address.');
        return false;
      }
      if (email !== emailcopy) {
        this.set('emailCopyError', 'Your email address must match.');
        return false;
      }
      if (password.length === 0) {
        this.set('passwordError', 'You must enter a password.');
        return false;
      }
      if (validatePasswordComplexity) {
        // TODO - add complexity requirements
        if (password.length < 8) {
          this.set('passwordError', 'You password must be at least 8 characters long.');
          return false;
        }
      }
      return true;
    },

    emailError: null,
    emailCopyError: null,
    passwordError: null,

    handleError: function handleError(error) {
      console.log(error);
      this.reset(false);
      if (!error) {
        return;
      }
      if (!error.code) {
        this.set('error', error);
      }
      // https://firebase.google.com/docs/reference/js/firebase.auth.Auth
      switch (error.code) {
        // Sign in errors
        // Should only happen if we support multiple providers
        // case "auth/account-exists-with-different-credential":
        //   this.set('error', '');
        //   break;
        case "auth/invalid-credential":
          this.set('error', 'There was something wrong with the credentials provided.');
          break;
        // case "auth/operation-not-allowed":
        //   this.set('error', '');
        //   break;
        case "auth/user-disabled":
          this.set('emailError', 'Your account has been disabled.');
          break;
        case "auth/user-not-found":
          this.set('emailError', 'There is no account with that email address.');
          break;
        case "auth/wrong-password":
          this.set('passwordError', 'The password you entered is incorrect.');
          break;
        // Create user errors
        case "auth/email-already-in-use":
          this.set('emailError', 'There is already an account with this email address. Please try logging in instead.');
          break;
        case "auth/invalid-email":
          this.set('emailError', 'You must enter a valid email address.');
          break;
        case "auth/weak-password":
          this.set('passwordError', 'You password must be at least 8 characters long.');
          break;
        default:
          this.set('error', error.message);
      }
    },

    actions: {
      create: function create() {
        var _this = this;

        if (!this.validateCredentials(true) || this.get('authenticating')) {
          return;
        }
        this.set("authenticating", true);
        var auth = this.get('firebaseApp').auth();
        auth.createUserWithEmailAndPassword(this.get('email'), this.get('password')).then(function (userResponse) {
          if (!userResponse) {
            _this.set("authenticating", false);
            _this.set('error', 'Something went wrong... please try refreshing the page and try again.');
            return;
          }
          _this.set('error', null);
          _this.get('session').fetch().then(function () /*results*/{
            console.log(_this.get('session'));
            _this.get('signup').createBusinessUser().then(function () {
              _this.get('signup').persistLogon();
              _this.set("authenticating", false);
              if (_this.get('query.values.pocketpay')) {
                _this.get('target').transitionTo('migrate-cloud');
              } else {
                _this.get('target').transitionTo('created');
              }
            });
          });
        })['catch'](function (error) {
          _this.set("authenticating", false);
          _this.handleError(error);
        });
      }
    }
  });
});