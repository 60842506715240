define('signup/components/wizard-entities', ['exports', 'ember', 'signup/components/wizard-base'], function (exports, _ember, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    defaults: {
      persons: [{
        started: true,
        active: true,
        counter: 0
      }]
    },

    personCounter: 0,

    extraPersons: _ember['default'].computed('data.persons.length', function () {
      return this.get('data.persons.length') - 1;
    }),

    setActiveTab: _ember['default'].observer('active', function () {
      if (this.get('active')) {
        try {
          this.set('activePerson', this.get('data.persons')[0]);
        } catch (e) {
          this.set('activePerson', null);
        }
      } else {
        this.set('activePerson', null);
      }
    }),

    uploading: _ember['default'].computed('data.persons.@each.idUploading', 'data.persons.@each.idUploading2', 'data.persons.@each.bankcruptcyUploading', function () {
      var uploading = false;
      var persons = this.get('data.persons');
      if (!persons) {
        return uploading;
      }
      persons.forEach(function (p) {
        if (p.idUploading || p.idUploading2 || p.bankcruptcyUploading) {
          uploading = true;
        }
      });
      return uploading;
    }),

    validate: function validate() {
      var _this = this;

      var valid = true;
      // Make all inactive to force validation
      this.set('activePerson', null);
      this.get('data.persons').forEach(function (person) {
        person._forceValidate();
        if (!person.isValid) {
          if (valid) {
            _this.set('activePerson', person);
          }
          valid = false;
        }
      });
      this.set('data.isValid', valid);
      this.set('isValid', valid);
      return valid;
    },

    actions: {
      addAnother: function addAnother() {
        var persons = this.get('data.persons');
        var counter = this.get('personCounter') + 1;
        this.set('personCounter', counter);
        persons.pushObject({ started: true, counter: counter });
        this.notifyPropertyChange('data.persons');
        this.set('activePerson', persons[persons.get('length') - 1]);

        $('body').animate({
          scrollTop: this.$().offset().top - 20
        }, 150);
      },
      promptDeletePerson: function promptDeletePerson(person) {
        this.set('deletePerson', person);
        this.set('deleteModal', true);
      },
      deletePerson: function deletePerson(person) {
        var persons = this.get('data.persons');
        var index = persons.indexOf(person);
        persons.removeAt(index);
        this.set('activePerson', persons[0]);
        this.set('deleteModal', false);
      },
      setActive: function setActive(person) {
        this.set('activePerson', person);
      },
      saveField: function saveField(person, field, data) {
        var key = this.get('data.persons').indexOf(person);
        this.sendAction('saveField', 'persons/' + key + '/' + field, data);
      }
    }
  });
});