define('signup/controllers/reset-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    firebaseApp: _ember['default'].inject.service(),
    query: _ember['default'].inject.service(),
    lockEmail: false,
    resetPassword: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var email = this.get('query.values.email');
      if (email) {
        this.set('email', email);
        this.set('lockEmail', true);
        _ember['default'].run.later(function () {
          _this.send('reset-password');
        });
      }
    },

    reset: function reset() {
      var resetFields = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      if (resetFields) {
        this.set('email', '');
        this.set('lockEmail', false);
      }
      this.set('error', null);
      this.set('emailError', null);
    },

    validateCredentials: function validateCredentials() {
      var email = this.get('email');
      this.reset(false);
      if (!/.+@.+\..+/.test(email)) {
        this.set('emailError', 'You must enter a valid email address.');
        return false;
      }
      return true;
    },
    emailError: null,
    success: null,

    handleError: function handleError(error) {
      console.log(error);
      this.reset(false);
      if (!error) {
        return;
      }
      if (!error.code) {
        this.set('error', error);
      }
      // https://firebase.google.com/docs/reference/js/firebase.auth.Auth
      switch (error.code) {
        case "auth/user-disabled":
          this.set('emailError', 'Your account has been disabled.');
          break;
        case "auth/user-not-found":
          this.set('emailError', 'There is no account with that email address.');
          break;
        case "auth/invalid-email":
          this.set('emailError', 'You must enter a valid email address.');
          break;
        case "auth/internal-error":
          this.set('emailError', 'You have exceeded the Password reset limit. Please try again 30 min.');
          break;
        default:
          this.set('error', error.message);
      }
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        if (!this.validateCredentials() || this.get('resetPassword')) {
          return;
        }

        this.set("resetPassword", true);

        var auth = this.get('firebaseApp').auth();
        var email = this.get('email');
        auth.sendPasswordResetEmail(email).then(function () {
          _this2.set('resetPassword', false);
          _this2.set('success_message', 'Your reset password email has been sent.');
        })['catch'](function (error) {
          _this2.set('resetPassword', false);
          _this2.handleError(error);
        });
      }
    }

  });
});