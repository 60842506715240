define('signup/components/wizard-security', ['exports', 'signup/components/wizard-base'], function (exports, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    defaults: {},

    validation: {
      'infoStolen': [{
        validate: function validate(value) {
          return value === true || value === false;
        },
        error: 'You must select either Yes or No.'
      }],
      'internetSales': [{
        validate: function validate(value) {
          return value === true || value === false;
        },
        error: 'You must select either Yes or No.'
      }],
      'thirdPartyTransactions': [{
        validate: function validate(value) {
          return value === true || value === false;
        },
        error: 'You must select either Yes or No.'
      }],
      'thirdParty': [{
        prevalidate: function prevalidate() {
          return this.get('data.thirdPartyTransactions');
        },
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must enter the name of the third party.'
      }],
      'storeCardHolderInfo': [{
        validate: function validate(value) {
          return value === true || value === false;
        },
        error: 'You must select either Yes or No.'
      }],
      'expectedRevenue': [{
        validate: function validate(value) {
          return Number((value || '').replace(/\$|,/g, '')) > 0;
        },
        error: 'You must enter an amount greater than $0.'
      }],
      'averageSaleAmount': [{
        validate: function validate(value) {
          return Number((value || '').replace(/\$|,/g, '')) > 0;
        },
        error: 'You must enter an amount greater than $0.'
      }]
    },

    actions: {
      update: function update(field, value) {
        this.set('data.' + field, value);
      }
    }
  });
});
// import Ember from 'ember';