define('signup/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    firebaseApp: _ember['default'].inject.service(),
    signup: _ember['default'].inject.service(),
    query: _ember['default'].inject.service(),

    authenticating: false,
    renterPassword: false,
    lockEmail: false,
    autoLogin: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var email = this.get('query.values.email');
      var password = this.get('query.values.password');
      if (email) {
        this.set('email', email);
        this.set('lockEmail', true);
        if (password) {
          this.set('password', password);
          this.set('autoLogin', true);
          _ember['default'].run.later(function () {
            _this.send('login');
          });
        } else {
          this.set('renterPassword', true);
        }
      }
    },

    reset: function reset() {
      var resetFields = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      if (resetFields) {
        this.set('email', '');
        this.set('password', '');
        this.set('lockEmail', false);
        this.set('renterPassword', false);
      }
      this.set('error', null);
      this.set('emailError', null);
      this.set('passwordError', null);
    },

    validateCredentials: function validateCredentials() {
      var validatePasswordComplexity = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var email = this.get('email');
      var password = this.get('password') || "";
      this.reset(false);
      if (!/.+@.+\..+/.test(email)) {
        this.set('emailError', 'You must enter a valid email address.');
        return false;
      }
      if (password.length === 0) {
        this.set('passwordError', 'You must enter a password.');
        return false;
      }
      if (validatePasswordComplexity) {
        // TODO - add complexity requirements
        if (password.length < 8) {
          this.set('passwordError', 'You password must be at least 8 characters long.');
          return false;
        }
      }
      return true;
    },

    emailError: null,
    passwordError: null,

    handleError: function handleError(error) {
      console.log(error);
      this.reset(false);
      if (!error) {
        return;
      }
      if (!error.code) {
        this.set('error', error);
      }
      // https://firebase.google.com/docs/reference/js/firebase.auth.Auth
      switch (error.code) {
        // Sign in errors
        // Should only happen if we support multiple providers
        // case "auth/account-exists-with-different-credential":
        //   this.set('error', '');
        //   break;
        case "auth/invalid-credential":
          this.set('error', 'There was something wrong with the credentials provided.');
          break;
        // case "auth/operation-not-allowed":
        //   this.set('error', '');
        //   break;
        case "auth/user-disabled":
          this.set('emailError', 'Your account has been disabled.');
          break;
        case "auth/user-not-found":
          this.set('emailError', 'There is no account with that email address.');
          break;
        case "auth/wrong-password":
          this.set('passwordError', 'The password you entered is incorrect.');
          break;
        // Create user errors
        case "auth/email-already-in-use":
          this.set('emailError', 'There is already an account with this email address. Please try logging in instead.');
          break;
        case "auth/invalid-email":
          this.set('emailError', 'You must enter a valid email address.');
          break;
        case "auth/weak-password":
          this.set('passwordError', 'You password must be at least 8 characters long.');
          break;
        default:
          this.set('error', error.message);
      }
    },

    actions: {
      login: function login() {
        var _this2 = this;

        if (!this.validateCredentials() || this.get('authenticating')) {
          this.set('autoLogin', false);
          return;
        }
        this.set("authenticating", true);
        this.get('session').open('firebase', {
          provider: 'password',
          email: this.get('email'),
          password: this.get('password')
        }).then(function () /*data*/{
          _this2.set('error', null);
          _this2.get('signup').fetch(true).then(function () /*user*/{
            _this2.get('signup').persistLogon();
            var transition = undefined;
            if (_this2.get('query.values.pocketpay')) {
              transition = _this2.get('target').transitionTo('migrate-cloud');
            } else {
              transition = _this2.get('target').transitionTo('setup');
            }
            transition['finally'](function () {
              _this2.set("authenticating", false);
            });
          });
        }, function (error) {
          _this2.set("authenticating", false);
          _this2.set('autoLogin', false);
          _this2.handleError(error);
        });
      }
    }
  });
});