define('signup/services/cloudeftpos', ['exports', 'ember', 'signup/config/environment'], function (exports, _ember, _signupConfigEnvironment) {

  var API_HOST = _signupConfigEnvironment['default'].environment === "production" ? "https://api.cloudeftpos.com" : "https://api.test.cloudeftpos.com";

  exports['default'] = _ember['default'].Service.extend({
    login: function login(email, password) {
      return new Promise(function (res, rej) {
        var settings = {
          "async": true,
          "crossDomain": true,
          "url": API_HOST + '/Commission.svc/VerifyAuthentication',
          "method": "POST",
          "contentType": "text/json",
          "dataType": "json",
          "timeout": 10000,
          "data": JSON.stringify({
            request: {
              Email: email,
              Password: password
            }
          })
        };

        $.ajax(settings).done(function (result, textStatus, jqXHR) {
          if (result.ErrorMessage || !result.Success) {
            rej(result.ErrorMessage);
          }
          res();
        }).fail(function (jqXHR, textStatus, errorThrown) {
          rej(errorThrown);
        });
      });
    }
  });
});