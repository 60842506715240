define('signup/controllers/created', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    download: false,

    actions: {
      download: function download() {
        this.set('download', true);
      }
    }
  });
});