define('signup/components/image-uploader', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    uploading: false,
    allowPdf: false,
    classNames: ['relative'],

    accept: _ember['default'].computed('allowPdf', function () {
      return this.get('allowPdf') ? 'application/pdf,image/*' : 'image/*';
    }),

    isPdf: _ember['default'].computed('imageUrl', function () {
      var url = this.get('imageUrl');
      var extension = (url || '').split('?')[0].split('.').pop().toLowerCase();
      return extension === 'pdf';
    }),

    isImage: _ember['default'].computed('imageUrl', function () {
      var url = this.get('imageUrl');
      var extension = (url || '').split('?')[0].split('.').pop().toLowerCase();
      return ['jpg', 'jpeg', 'gif', 'png'].indexOf(extension) > -1;
    }),

    filename: _ember['default'].computed('imageUrl', function () {
      var url = this.get('imageUrl');
      return (url || '').split('?')[0].replace(/%2F/g, '/').split('/').pop();
    }),

    actions: {
      onProgress: function onProgress(data) {
        this.set('uploading', true);
        console.log(data);
      },
      onComplete: function onComplete(error, data) {
        this.set('uploading', false);
        console.log(error, data);
        if (error) {
          return;
        }
        if (data.downloadURL) {
          this.set('imageUrl', data.downloadURL);
        }
      },
      'delete': function _delete() {
        this.set('imageUrl', null);
      }
    }
  });
});