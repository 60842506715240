define('signup/initializers/initialize-torii-callback', ['exports', 'signup/config/environment', 'torii/redirect-handler'], function (exports, _signupConfigEnvironment, _toriiRedirectHandler) {
  exports['default'] = {
    name: 'torii-callback',
    before: 'torii',
    initialize: function initialize(application) {
      if (arguments[1]) {
        // Ember < 2.1
        application = arguments[1];
      }
      if (_signupConfigEnvironment['default'].torii && _signupConfigEnvironment['default'].torii.disableRedirectInitializer) {
        return;
      }
      application.deferReadiness();
      _toriiRedirectHandler['default'].handle(window)['catch'](function () {
        application.advanceReadiness();
      });
    }
  };
});