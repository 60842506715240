define('signup/router', ['exports', 'ember', 'signup/config/environment'], function (exports, _ember, _signupConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _signupConfigEnvironment['default'].locationType,
    rootURL: _signupConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('create');
    this.route('created');
    this.route('login');
    this.route('reset-password');
    this.route('choose-plan');
    this.route('setup');
    this.route('application-complete');
    this.route('migrate-cloud');
    this.route('migrate-complete');
    this.route('pocketpay');
  });

  exports['default'] = Router;
});