define('signup/components/wizard-bank', ['exports', 'ember', 'signup/components/wizard-base'], function (exports, _ember, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    defaults: {
      feesAsAbove: true
    },

    autoSaveSettlementStatement: _ember['default'].observer('data.settlementStatement', function () {
      if (this.get('data.settlementStatement')) {
        this.sendAction('saveField', 'settlementStatement', this.get('data.settlementStatement'));
      }
    }),

    validation: {
      'settlementAccountName': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must enter an account name.'
      }],
      'settlementAccountBsb': [{
        validate: function validate(value) {
          return (value || '').replace(/ |_/g, '').length === 6;
        },
        error: 'You must enter a valid BSB.'
      }],
      'settlementAccountNumber': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must enter an account number.'
      }],
      'settlementStatement': [{
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a bank statement.'
      }],
      'feesAccountName': [{
        prevalidate: function prevalidate() {
          return !this.get('data.feesAsAbove');
        },
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must enter an account name.'
      }],
      'feesAccountBsb': [{
        prevalidate: function prevalidate() {
          return !this.get('data.feesAsAbove');
        },
        validate: function validate(value) {
          return (value || '').replace(/ |_/g, '').length === 6;
        },
        error: 'You must enter a valid BSB.'
      }],
      'feesAccountNumber': [{
        prevalidate: function prevalidate() {
          return !this.get('data.feesAsAbove');
        },
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must enter an account number.'
      }],
      'feesStatement': [{
        prevalidate: function prevalidate() {
          return !this.get('data.feesAsAbove');
        },
        validate: function validate(value) {
          return (value || '') !== '';
        },
        error: 'You must provide a bank statement.'
      }]
    },

    actions: {
      feesAsAbove: function feesAsAbove(enable) {
        this.set('data.feesAsAbove', enable);
        this.set('feesAsAbove', enable);
      },
      settlementStatementError: function settlementStatementError(error) {
        this.set('errors.settlementStatement', error);
      },
      feesStatementError: function feesStatementError(error) {
        this.set('errors.feesStatement', error);
      }
    }
  });
});