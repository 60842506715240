define('signup/helpers/cosh', ['exports', 'ember-math-helpers/helpers/cosh'], function (exports, _emberMathHelpersHelpersCosh) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersCosh['default'];
    }
  });
  Object.defineProperty(exports, 'cosh', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersCosh.cosh;
    }
  });
});