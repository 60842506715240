define('signup/components/wizard-base', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].run.later(function () {
        // Have to do later to ensure bindings are setup
        if (!_this.get('data')) {
          _this.set('data', {});
        }
        var defaults = _this.get('defaults');
        Object.keys(defaults).forEach(function (key) {
          if (_this.get('data.' + key) === undefined) {
            _this.set('data.' + key, defaults[key]);
          }
        });
      });
    },

    isVisible: _ember['default'].computed('data.started', function () {
      return this.get('data.started') === true;
    }),

    defaults: {},
    data: {},
    errors: {},
    validation: {},
    isValid: true, // Default true for display purposes
    validate: function validate() {
      var _this2 = this;

      var validation = this.get('validation');
      var errors = {},
          error = false;
      Object.keys(validation).forEach(function (key) {
        validation[key].forEach(function (validator) {
          if (validator.prevalidate) {
            if (!validator.prevalidate.call(_this2)) {
              return;
            }
          }
          if (!validator.validate.call(_this2, _this2.get('data.' + key))) {
            errors[key] = validator.error;
            error = true;
          }
        });
      });
      this.set('errors', errors);
      this.set('data.isValid', !error);
      this.set('isValid', !error);
      return !error;
    },
    wasActive: false,
    checkValidity: _ember['default'].observer('active', function () {
      var _this3 = this;

      if (this.get('active')) {
        if (!this.get('wasActive')) {
          setTimeout(function () {
            $('body').animate({
              scrollTop: _this3.$().offset().top - 20
            }, 150);
          }, 300);
        }
        this.set('wasActive', true);
        return;
      }
      if (!this.get('wasActive')) {
        return;
      }
      // If no longer active, validate
      this.set('wasActive', false);
      this.validate();
    }),

    actions: {
      next: function next() {
        this.sendAction('save', true);
        if (!this.validate()) {
          return;
        }
        this.sendAction('next', this.get('data'));
      },
      edit: function edit() {
        this.validate(); // Make sure fields show errors
        this.sendAction('edit');
      }
    }
  });
});