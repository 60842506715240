define('signup/components/wizard-plan', ['exports', 'ember', 'signup/components/wizard-base'], function (exports, _ember, _signupComponentsWizardBase) {
  exports['default'] = _signupComponentsWizardBase['default'].extend({
    isVisible: true, // Override - since plan is first it is always visible
    feesModal: false,
    defaults: {
      terminalQuantity: 1,
      totalPrice: 23.00
    },

    totalObserver: _ember['default'].observer('data.terminalQuantity', function () {
      this.set('data.totalPrice', this.get('data.terminalQuantity') * this.get('price'));
    }),
    price: 23.00,
    quantityValues: [1, 2, 3, 4, 5],
    validation: {},

    validateQuantity: function validateQuantity() {
      if (this.get('data.terminalQuantity') > 5) {
        this.set('data.terminalQuantity', 5);
      }
      if (this.get('data.terminalQuantity') < 1) {
        this.set('data.terminalQuantity', 1);
      }
    },

    actions: {
      'continue': function _continue() {
        this.set('feesModal', false);
      },
      decrement: function decrement() {
        this.decrementProperty('data.terminalQuantity');
        if (this.get('data.terminalQuantity') < 1) {
          this.set('data.terminalQuantity', 1);
        }
      },
      increment: function increment() {
        this.incrementProperty('data.terminalQuantity');
        this.validateQuantity();
      },
      validateQuantity: function validateQuantity() {
        this.validateQuantity();
      }
    }
  });
});