define('signup/controllers/setup', ['exports', 'ember', 'firebase'], function (exports, _ember, _firebase) {
  exports['default'] = _ember['default'].Controller.extend({
    signup: _ember['default'].inject.service(),
    step: 'plan',
    saving: 0,
    isSaving: false,
    didSave: false,
    errorModal: false,
    errorMessage: '',

    _isSaving: _ember['default'].observer('saving', function () {
      var _this = this;

      if (this.get('saving') > 0) {
        this.set('isSaving', true);
      } else {
        if (this.get('isSaving')) {
          this.set('didSave', true);
          setTimeout(function () {
            _this.set('didSave', false);
          }, 2000);
        }
        this.set('isSaving', false);
      }
    }),

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      _ember['default'].run.later(function () {
        if (!_this2.get('signup.application')) {
          _this2.set('signup.application', {
            started: _firebase['default'].database.ServerValue.TIMESTAMP
          });
        }
        if (_this2.get('signup.application.finished')) {
          _this2.get('target').transitionTo('application-complete');
        }
        var currentStep = _this2.get('signup.application.currentStep');
        if (currentStep) {
          _this2.set('step', currentStep);
        }
      });

      var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
            s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/5976e0d60d1bb37f1f7a5b57/default';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    },

    actions: {
      next: function next(step) {
        var _this3 = this;

        this.incrementProperty('saving');
        this.set('signup.application.currentStep', step);
        if (!this.get('signup.application.' + step)) {
          this.set('signup.application.' + step, {});
        }
        var moveOn = !this.get('signup.application.' + step + '.started');
        this.set('signup.application.' + step + '.started', true);
        this.get('signup').save().then(function () {
          _this3.decrementProperty('saving');
          _this3.set('step', moveOn ? step : null);
        })['catch'](function (error) {
          console.log(error);
          _this3.set('errorMessage', error);
          _this3.set('errorModal', true);
          _this3.decrementProperty('saving');
        });
      },
      edit: function edit(step) {
        this.set('step', step);
      },
      save: function save() {
        var _this4 = this;

        var foreground = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        if (this.get('saving')) {
          return;
        }
        if (!foreground) {
          this.get('signup').save();
        }
        this.incrementProperty('saving');
        this.get('signup').save().then(function () {
          _this4.decrementProperty('saving');
        })['catch'](function (error) {
          console.log(error);
          _this4.set('errorMessage', error);
          _this4.set('errorModal', true);
          _this4.decrementProperty('saving');
        });
      },
      saveField: function saveField(step, field, value) {
        this.get('signup').saveField(step, field, value);
      },
      submit: function submit() {
        var _this5 = this;

        if (this.get('saving')) {
          return;
        }
        this.set('saving', true);
        this.set('signup.application.finished', _firebase['default'].database.ServerValue.TIMESTAMP);
        this.get('signup').save().then(function () {
          _this5.get('signup').setApplicationProgress();
          _this5.get('target').transitionTo('application-complete');
        })['catch'](function (error) {
          console.log(error);
          _this5.set('errorMessage', error);
          _this5.set('errorModal', true);
          _this5.set('saving', false);
        });
      }
    }
  });
});