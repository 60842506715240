define('signup/controllers/migrate-cloud', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    cloudeftpos: _ember['default'].inject.service(),
    signup: _ember['default'].inject.service(),

    reset: function reset() {
      var resetFields = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      if (resetFields) {
        this.set('email', '');
        this.set('password', '');
      }
      this.set('error', null);
      this.set('emailError', null);
      this.set('passwordError', null);
    },

    validateCredentials: function validateCredentials() {
      var email = this.get('email');
      var password = this.get('password') || "";
      this.reset(false);
      if (!/.+@.+\..+/.test(email)) {
        this.set('emailError', 'You must enter a valid email address.');
        return false;
      }
      if (password.length === 0) {
        this.set('passwordError', 'You must enter a password.');
        return false;
      }
      return true;
    },

    actions: {
      login: function login() {
        var _this = this;

        if (!this.validateCredentials()) {
          return;
        }
        this.set("authenticating", true);
        this.set('error', false);

        var email = this.get('email');
        var password = this.get('password');

        this.get('cloudeftpos').login(email, password).then(function () {
          _this.get('signup').setCloudDetails(email, password).then(function () {
            _this.get('target').transitionTo('migrate-complete');
          });
        })['catch'](function (error) {
          _this.set('error', error);
        }).then(function () {
          _this.set("authenticating", false);
        });
      }
    }
  });
});