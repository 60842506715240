define("signup/helpers/format-currency", ["exports", "ember"], function (exports, _ember) {
  exports.formatCurrency = formatCurrency;

  var defaultCurrency = "$";
  var defaultDecimal = ".";
  var defaultSeparator = ",";

  function formatCurrency(params, hash) {
    var cents = params[0];
    var currency = 'currency' in hash ? hash.currency : defaultCurrency;
    var decimal = 'decimal' in hash ? hash.decimal : defaultDecimal;
    var separator = 'separator' in hash ? hash.separator : defaultSeparator;
    var dollars = 'dollars' in hash ? hash.dollars : false;
    var formatted = (dollars ? cents * 1 : cents * 0.01).toFixed(2).replace(".", decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + separator);
    return "" + currency + formatted;
  }

  exports["default"] = _ember["default"].Helper.helper(formatCurrency);
});